@import '~@which/seatbelt/src/styles/styles';

.campaignWin {
  &Header {
    text-align: center;
    margin-bottom: $sb-spacing-m;
  }

  &Standfirst {
    @include sb-respond-to(large) {
      margin-bottom: $sb-spacing-xl;
    }

    text-align: center;
    margin-bottom: $sb-spacing-l;
  }

  &ThreeCol {
    @include sb-respond-to(medium-up) {
      display: grid;
      grid-gap: 26px;
      grid-template-columns: 1fr 1fr 1fr;

      img {
        max-width: auto;
        max-height: 216px;
        object-fit: cover;
      }
    }
  }
}

.showMoreWrapper {
  position: relative;
  margin-top: -50px;
  margin-bottom: $sb-spacing-xl;

  @include sb-respond-to(large) {
    margin-bottom: $sb-spacing-3xl;
  }

  @include sb-respond-to(medium-up) {
    margin-top: -70px;
  }

  &Button {
    width: 100%;
    padding-bottom: 0;

    button {
      display: block;
      margin: auto;
    }
  }
}

div[class^='StandardCard_cardLabel'] {
  width: 92px;
  margin-bottom: $sb-spacing-s;
}
