@import '~@which/seatbelt/src/styles/styles';

.verticalHeader {
  margin: $sb-spacing-xl 0 48px;
}

.verticalHeaderNews {
  margin: $sb-spacing-xl 0;

  p {
    text-align: center;
    width: 100%;
    padding: 0;
  }
}

.adviceCardsWrapper {
  margin: 20px 0;

  @include sb-respond-to(medium-up) {
    margin: 30px 0 20px;
  }

  @include sb-respond-to(large) {
    margin: 60px 0 $sb-spacing-xl;
  }
}

// GAM panel vertical spacing
:global(.vertical-dfp-container) {
  padding: $sb-spacing-xl 0;
}
