@import '~@which/seatbelt/src/styles/styles';

.header {
  margin-bottom: $sb-spacing-l;
}

.list {
  @include sb-reset-list;

  display: grid;
  gap: 28px;

  &Expanded {
    // Override unwanted margin from ShowMore wrapper when expanded on mobile
    @include sb-respond-to(small) {
      margin-bottom: -34px;
    }
  }

  &Item {
    display: flex;
    align-items: flex-start;
  }
}

.showMoreWrapper {
  margin-bottom: 30px;

  @include sb-respond-to(medium-up) {
    margin-bottom: 0;
  }

  > div {
    padding-bottom: 0;
  }
}

.number {
  flex-basis: 28px;
}

.link {
  flex: 1;
  word-break: break-word;
}

.number,
.link {
  line-height: 28px;
}

.decorativeLink {
  margin-bottom: $sb-spacing-m;
}

.hiddenLink {
  display: none;
}

.top {
  border-top: $sb-border-width-s solid #ccc;
}

.gridItem {
  padding-top: $sb-spacing-xl;
}

.grid {
  margin-bottom: 60px;
}
