@import '~@which/seatbelt/src/styles/styles';

.collection {
  padding: $sb-spacing-xl 0;
  background-color: $sb-colour-background-default;

  &.isEven {
    background-color: $sb-colour-background-subtle;
  }
}
