@import '~@which/seatbelt/src/styles/styles';

.sitemapWrapper {
  grid-column-start: 1;
  grid-column-end: 13;
  padding: $sb-spacing-xl 0;
  display: grid;
  row-gap: 25px;

  .sitemapRow {
    display: grid;
    row-gap: 25px;
    .rootMenuLevel {
      display: grid;
      row-gap: 20px;

      .sitemapRow {
        a p {
          font-size: 18px;
          font-family: $sb-font-family-medium;

          @include sb-respond-to(medium-up) {
            text-transform: none;
          }
        }
      }

      .rootMenuLevel {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
        row-gap: 10px;

        @include sb-respond-to(medium-up) {
          grid-template-columns: repeat(2, 1fr);
          row-gap: 25px;
        }

        @include sb-respond-to(large) {
          grid-template-columns: repeat(3, 1fr);
        }

        a p {
          font-size: 18px;
          font-family: $sb-font-family-medium;
          font-weight: 400;
          padding-left: 10px;
        }

        .sitemapRow {
          a p {
            font-size: 18px;
            font-family: $sb-font-family-regular;
          }
        }

        .rootMenuLevel {
          display: grid;
          grid-template-columns: 1fr;
          a p {
            font-size: 18px;
            font-family: $sb-font-family-regular;
          }
        }
      }
    }
  }
}

.rowBorder:not(:last-child) {
  border-bottom: $sb-border-width-s solid $sb-colour-border-default;
  padding-bottom: 20px;
}

.rootElement > a {
  p {
    text-transform: uppercase;

    @include sb-respond-to(medium-up) {
      text-transform: none;
    }
  }
}
