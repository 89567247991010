@import '~@which/seatbelt/src/styles/styles';

.campaignsTakeAction {
  text-align: center;
  margin: $sb-spacing-l auto;

  @include sb-respond-to(medium-up) {
    margin: $sb-spacing-3xl auto;
  }

  &Header {
    margin-bottom: $sb-spacing-xs;
    @include sb-font-bold;

    @include sb-respond-to(medium-up) {
      margin-bottom: $sb-spacing-m;
    }
  }

  &Standfirst {
    margin-bottom: $sb-spacing-l;

    @include sb-respond-to(medium-up) {
      margin-bottom: $sb-spacing-l;
    }
  }

  &Icon {
    width: 50px;
    height: auto;
    margin-bottom: $sb-spacing-xs;

    @include sb-respond-to(medium-up) {
      width: 58px;
    }
  }

  &Number {
    @include sb-font-bold;
  }

  &Campaigns {
    display: flex;

    @include sb-respond-to(small) {
      flex-direction: column;
      align-items: center;
      gap: $sb-spacing-m;
    }

    @include sb-respond-to(medium-up) {
      justify-content: center;
    }
  }

  &Content {
    align-items: flex-end;
    display: flex;
    text-align: left;
    padding-bottom: $sb-spacing-xs;

    @include sb-respond-to(medium-up) {
      padding: 0 48px $sb-spacing-s;

      &:not(:last-of-type) {
        border-right: $sb-border-width-s solid $sb-colour-border-muted;
      }
    }

    &Wrapper {
      margin-left: $sb-spacing-s;
    }
  }
}
