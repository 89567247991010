@import '~@which/seatbelt/src/styles/styles';

.currentCampaign {
  height: 100%;
}

.currentCampaignItem {
  display: flex;
  flex-direction: column;
  max-width: 330px;
  min-height: 590px;

  @include sb-respond-to(large) {
    max-width: none;
    min-height: auto;
  }
}

.currentCampaignCard {
  &:hover {
    box-shadow: 2px 6px 6px -2px $sb-colour-background-disabled;

    h3 {
      background-size: 100% 1px;
    }
  }

  border: solid 1px $sb-colour-border-muted;
  border-radius: $sb-border-radius-s;
  display: flex;
  flex-direction: column;
  height: 100%;

  h3 {
    @media (hover: hover) {
      transition: background-size 0.4s;
    }

    background-image: linear-gradient(currentcolor, currentcolor);
    background-size: 0 1px;
    background-repeat: no-repeat;
    background-position: bottom left;
    display: inline;
    transition: 0.5s ease;

    &:hover {
      span {
        background-size: 100%;
      }
    }

    a {
      color: $sb-colour-text-default;
      text-decoration: none;
    }

    &:after {
      background-color: $sb-colour-background-inverse;
      bottom: 0;
      content: '';
      height: 1px;
      left: 0;
      position: absolute;
      transform: scaleX(0);
      transform-origin: bottom left;
      transition: transform 0.25s ease-out;
      width: 100%;
    }
  }

  &Details {
    padding: $sb-spacing-m $sb-spacing-l;
    flex: 1;
    display: flex;
    flex-direction: column;

    progress::-webkit-progress-bar {
      background-color: $sb-colour-background-subtle;
      width: 100%;
      border-radius: $sb-border-radius-m;
      height: $sb-spacing-s;
    }

    progress {
      background-color: $sb-colour-background-subtle;
      color: $sb-colour-text-link-default;
      border-radius: $sb-border-radius-m;
      height: $sb-spacing-s;
      width: 100%;
      margin-bottom: $sb-spacing-xs;
    }

    progress::-webkit-progress-value {
      background-color: $sb-colour-background-action-primary-default !important;
      border-radius: $sb-border-radius-m;
      height: $sb-spacing-s;
    }

    progress::-moz-progress-bar {
      background-color: $sb-colour-background-action-primary-default !important;
      border-radius: $sb-border-radius-m;
      height: $sb-spacing-s;
    }

    .signatureCount {
      display: flex;
      margin-top: auto;
      padding-bottom: $sb-spacing-xs;

      p {
        display: flex;
        align-items: end;
        margin: $sb-spacing-s 6px 0 0;
      }
    }

    h3 {
      @include sb-respond-to(large) {
        margin-bottom: $sb-spacing-s;
      }

      a {
        color: $sb-colour-text-default;

        &:visited {
          color: $sb-colour-text-default;
        }
      }
    }
  }

  &BottomCard {
    @include sb-respond-to(large) {
      display: flex;
      flex-direction: row-reverse;
      border: 1px solid $sb-colour-border-muted;
    }

    .currentCampaignCard {
      &Details {
        position: relative;
        flex: 0 0 55%;
      }

      &Image {
        flex: 1;
        > div {
          padding-top: 75%;
          top: 20px;
        }
      }
    }
  }
}

.mobileCarousel {
  margin-bottom: $sb-spacing-xl;

  [class*='cardRowScrollWrapper'] {
    padding-left: 0;
    padding-right: 11px;
  }

  img {
    max-height: 210px;
  }

  article {
    width: 278px;
    margin-right: $sb-spacing-l;
    height: 100%;
  }

  .currentCampaignCard {
    &Details {
      display: flex;
      flex-direction: column;
      min-height: 480px;
      padding: $sb-spacing-m $sb-spacing-l $sb-spacing-l;

      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        min-height: 528px;
      }

      h3 {
        margin-bottom: $sb-spacing-xs;
      }
    }

    .signatureCount {
      margin-top: auto;
      padding-bottom: $sb-spacing-s;
    }
  }
}

.currentCardsSingle {
  margin-bottom: $sb-spacing-3xl;

  > div {
    width: 50%;
  }
}

.currentCardsRow {
  display: flex;
  gap: $sb-spacing-l;
  margin-bottom: $sb-spacing-3xl;

  > div {
    flex: 1;
  }
}

.currentCardsGrid {
  display: grid;
  grid-template-rows: auto;
  gap: $sb-spacing-l;
  margin-bottom: $sb-spacing-3xl;

  &.grid4Cards {
    grid-template-areas:
      'card1 card2 card4 card4'
      'card3 card3 card4 card4';
  }

  &.grid5Cards {
    grid-template-areas:
      'card1 card2 card4 card4'
      'card3 card3 card5 card5';
  }

  &.grid6Cards {
    grid-template-areas:
      'card1 card1 card2 card2'
      'card3 card4 card5 card6';
  }

  > div {
    min-width: 0;

    &.card1 {
      grid-area: card1;
    }

    &.card2 {
      grid-area: card2;
    }

    &.card3 {
      grid-area: card3;
    }

    &.card4 {
      grid-area: card4;
    }

    &.card5 {
      grid-area: card5;
    }

    &.card6 {
      grid-area: card6;
    }
  }
}
